document.getElementById('login_input_email').addEventListener('focus', function() {
    document.getElementById('login-input-email-border-top').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-email-border-right').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-email-border-bottom').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-email-border-left').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-email-label').classList.remove('tw-text-blue-grey');

    document.getElementById('login-input-email-border-top').classList.add('tw-border-white');
    document.getElementById('login-input-email-border-right').classList.add('tw-border-white');
    document.getElementById('login-input-email-border-bottom').classList.add('tw-border-white');
    document.getElementById('login-input-email-border-left').classList.add('tw-border-white');
    document.getElementById('login-input-email-label').classList.add('tw-text-white');
});
document.getElementById('login_input_email').addEventListener('blur', function() {
    document.getElementById('login-input-email-border-top').classList.remove('tw-border-white');
    document.getElementById('login-input-email-border-right').classList.remove('tw-border-white');
    document.getElementById('login-input-email-border-bottom').classList.remove('tw-border-white');
    document.getElementById('login-input-email-border-left').classList.remove('tw-border-white');
    document.getElementById('login-input-email-label').classList.remove('tw-text-white');

    document.getElementById('login-input-email-border-top').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-email-border-right').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-email-border-bottom').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-email-border-left').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-email-label').classList.add('tw-text-blue-grey');
});

document.getElementById('login_input_password').addEventListener('focus', function() {
    document.getElementById('login-input-password-border-top').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-password-border-right').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-password-border-bottom').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-password-border-left').classList.remove('tw-border-blue-grey');
    document.getElementById('login-input-password-label').classList.remove('tw-text-blue-grey');

    document.getElementById('login-input-password-border-top').classList.add('tw-border-white');
    document.getElementById('login-input-password-border-right').classList.add('tw-border-white');
    document.getElementById('login-input-password-border-bottom').classList.add('tw-border-white');
    document.getElementById('login-input-password-border-left').classList.add('tw-border-white');
    document.getElementById('login-input-password-label').classList.add('tw-text-white');
});

document.getElementById('login_input_password').addEventListener('blur', function() {
    document.getElementById('login-input-password-border-top').classList.remove('tw-border-white');
    document.getElementById('login-input-password-border-right').classList.remove('tw-border-white');
    document.getElementById('login-input-password-border-bottom').classList.remove('tw-border-white');
    document.getElementById('login-input-password-border-left').classList.remove('tw-border-white');
    document.getElementById('login-input-password-label').classList.remove('tw-text-white');

    document.getElementById('login-input-password-border-top').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-password-border-right').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-password-border-bottom').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-password-border-left').classList.add('tw-border-blue-grey');
    document.getElementById('login-input-password-label').classList.add('tw-text-blue-grey');
});